@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    font-family: 'Nunito', sans-serif;
}

.text-walter-green{
    color : #388659;
}

.text-walter-blue{
    color : #0E4781;
}

.text-walter-light{
    color : #FFFCE8;
}

.bg-walter-green{
    background-color : #388659;
}

.bg-walter-blue{
    background-color : #0E4781;
}

.bg-walter-light{
    background-color : #FFFCE8;
}